<template>
  <div>

    <list-visit-add-new
        :no_of_days="no_of_days"
        ref="listVisitSidebar"
    />

    <b-col cols="2" class="mb-1" v-if="$can('create', 'visit')">
      <b-button
          variant="primary"
          @click="showListVisitSidebar"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </b-col>
    <b-col class="d-flex mb-1">
      <b-button
          class="mr-1"
          variant="outline-primary"
          @click="fetchPrevMonth"
      >
        <span class="text-nowrap"><b-icon-arrow-left class="mr-1" variant="primary"></b-icon-arrow-left>Пред. месяц</span>
      </b-button>
      <b-button
          variant="outline-primary"
          @click="fetchNextMonth"
      >
        <div class="text-nowrap">След. месяц<b-icon-arrow-right class="ml-1" variant="primary"></b-icon-arrow-right></div>
      </b-button>

    </b-col>
    <b-col class="col-12 mb-1 text-center">
       <strong :style="{color: isDark ? '#ffffff' : '#6e6b7b'}" class="text-nowrap" style="font-size: 15px;font-weight: 500">{{ no_of_days.format('MMMM YYYY') }}</strong>
    </b-col>

    <b-col cols="12" :key="index" class="mb-1" v-for="(visit, key, index) in items">
      <visit-table :group-name="key" :visit="visit" :no_of_days="no_of_days"></visit-table>
    </b-col>
  </div>
</template>

<script>
import {
  BButton, BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination, BRow,
  BTable, BIconEmojiSmileFill,BIconEmojiFrownFill,BIconEmojiNeutralFill,BIconArrowLeft,BIconArrowRight
} from "bootstrap-vue";
import ListVisitAddNew from "@/views/visit/ListVisitAddNew";
import moment from 'moment'
import VisitTable from '@/views/visit/VisitTable'

export default {
  name: 'ListVisit',
  components: {
    VisitTable,
    ListVisitAddNew,
    BButton, BLink,
    BTable, BFormGroup, BInputGroup, BFormInput,
    BInputGroupAppend, BPagination, BRow, BCol, BIconEmojiSmileFill, BIconEmojiFrownFill,BIconEmojiNeutralFill,BIconArrowLeft,BIconArrowRight
  },
  data() {
    const d = moment()
        d.locale('ru')
        d.set('date', 1)
    const month = d.get('month')
    const year = d.get('year')
    return {
      moment: moment,
      groupByItems: [],
      createUser: '',
      user: '',
      date: '',
      status: true,
      userId: 1,
      attendanceId: 1,
      add_days: [{
        name: "gsdjg';;'",
        dates: [
          true, false
        ]
      }, {
        name: "342g';;'",
        dates: [
          true, false
        ]
      }],
      users: [{
        id: 1,
        name: 'lorem ipsum',
        attendances: []
      }],
      month: month,
      year: year,
      no_of_days: d
    }
  },
  computed: {
    isDark() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },
    items() {
      console.log({items: this.$store.state.info.visitList})
      return this.$store.state.info.visitList
    },
  },
  created() {
    moment.locale('ru')
    // this.fetchAllData()
    this.fetchData(1)
  },
  methods: {
    visitStatus(day, child) {
      const date = this.no_of_days
      date.set('date', day)
      return child.dates.find(d => moment(d.date).isSame(date, 'day')) || null
    },
    isWeekend(day) {
      const date = new Date()
      date.setDate(day)
      return date.getDay() === 6 || date.getDay() === 0
    },
    async fetchAllData() {
      await this.$store.dispatch('info/getChildrenGroups')
      await this.fetchData(1)
    },
    fetchPrevMonth() {
      this.no_of_days.subtract(1, 'month')
      this.fetchData(1)
    },
    fetchNextMonth() {
      this.no_of_days.add(1, 'month')
      this.fetchData(1)
    },
    fetchData(page) {
      // params.limit = this.limit
      // params.offset = page === 1 ? 0 : (page * this.limit) - this.limit
      // if (this.filter !== '') {
      //   params.search = this.filter
      // }
      const params = {
        date: this.no_of_days.format('YYYY-MM')
      }
      this.$store.dispatch('info/getVisit', params)
    },
    showListVisitSidebar() {
      if (!this.$refs.listVisitSidebar.isAddListVisitSidebarActive) {
        this.$refs.listVisitSidebar.isAddListVisitSidebarActive = true
      }
    },
  },


  // fetchData(page) {
  //   const params = {
  //     limit: this.limit,
  //     offset: page === 1 ? 0 : (page * this.limit) - this.limit,
  //   }
  //   if (this.filter !== '') {
  //     params.search = this.filter
  //   }
  //   this.$store.dispatch('info/getWorkersScheduler', params)
  // },
  // filterData() {
  //   if (this.filter !== '') {
  //     this.fetchData(1)
  //   } else {
  //     this.fetchData(1)
  //   }
  // },
  // pageChanged(page) {
  //   this.fetchData(page)
  // },
  // onRowSelected(items) {
  //   this.$emit('items-selected', items)
  // },


}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/components/table.scss';

//.table-custom-active {
//  background-color: #161d31;
//}
//.list-view-row .list-visit-day.value{
//  font-size: 15px!important;
//}
//.list-view-row {
//  min-height: 60px;
//
//  .list-visit-child-name {
//    text-align: center;
//    width: 130px;
//    min-width: 65px;
//    font-size: 12px;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//  }
//
//  .list-visit-day {
//    text-align: center;
//    width: 65px;
//    min-width: 35px;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//  //Стили для последней колонки "Кол-во посещений"
//    &:last-child {
//      width: 85px;
//      &.head {
//      font-size: 10px;
//      }
//    }
//    &.value {
//      font-size: 8px;
//    }
//
//    &.head {
//      font-size: 12px;
//    }
//  }
//}
</style>
