<template>
  <div>
    <b-button class="mb-1" block @click="visible = !visible" variant="info">{{ groupName }}</b-button>
    <div style="margin: 10px;" v-if="visible">
      <b-button variant="primary" @click="print(groupName)">Распечатать</b-button>
    </div>
    <div :id="groupName" class="forprint">
      <div v-if="visible" class="list-view-row  d-flex w-100 justify-content-between border-top">
        <div class="list-visit-day border-left">№</div>
        <div class="list-visit-child-name border-right border-left td">
          <div class="text-center">ФИО Ребенка</div>
        </div>
        <div :class="{
            'td': true,
            'bg-warning': isWeekend(day),
            'list-visit-day': true,
              'head': true,
                'border-right': true
          }" :key="index" v-for="(day, index) in no_of_days.daysInMonth()"
        >
          <div>{{ day }}</div>
        </div>

        <div :class="{
            'list-visit-day': true,
              'head': true,
                'border-right': true
          }"
        >
          <div>Кол-во посещений</div>
        </div>

      </div>
      <div
        v-if="visible"
        :class="{
            'td': true,
        'list-view-row': true,
        'd-flex': true,
          'w-100': true,
          'justify-content-between': true,
            'border-top': true,
              'border-bottom': (visit.length - 1) === index
            }"
        :key="index" v-for="(child, index) in visit"
      >
        <div class="list-visit-day border-left">{{ index+1 }}</div>
        <div class="list-visit-child-name border-right border-left">
          <div>{{ child.name }}</div>
        </div>
        <div
          :class="{'list-visit-day': true, 'value': true, 'border-right': true, 'bg-warning': isWeekend(day)}"
          :key="index" v-for="(day, index) in no_of_days.daysInMonth()"
        >
          <div>
            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                {{ checkVisit(day, child) }} <span class="sr-only">status</span>
              </template>
              <b-dropdown-item :key="status.value" v-for="(status) in visitStatus"
                               @click="handleEditVisit({id: getVisitId(day, child), children: [child.id], status: status.value, day})"
              >{{ status.text }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

        </div>
        <div
          :class="{'list-visit-day': true, 'value': true, 'border-right': true}"
        >
          <div>{{ sumVisits(child) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BIconEmojiSmileFill, BIconEmojiFrownFill, BDropdown, BDropdownItem } from 'bootstrap-vue'
import moment from 'moment'

import emojiStatus from '../../libs/emoji-status'

export default {
  name: 'VisitTable',
  components: {
    BButton,
    BIconEmojiSmileFill,
    BIconEmojiFrownFill,
    BDropdown,
    BDropdownItem
  },
  props: ['no_of_days', 'groupName', 'visit'],
  data() {
    return {
      visible: false,
      countVisits: {},
      visitStatus: [
        {
          value: 'Ж',
          text: `${emojiStatus('Ж')} Отсутствует ребенок по желанию родителей`
        },
        {
          value: 'О',
          text: `${emojiStatus('О')} Отсутствует без предупеждения родителей`
        },
        {
          value: 'Был',
          text: `${emojiStatus('Был')} Был в саду`
        }
      ]
    }
  },
  methods: {
    checkVisit(day, child) {
      const visitStatus = this._visitStatus(day, child)
      return (visitStatus ? emojiStatus(visitStatus.status) : '')
    },
    getVisitId(day, child) {
      const date = this.no_of_days
      date.set('date', day)
      const visit = child.dates.find(d => moment(d.date)
        .isSame(date, 'day')) || null
      return (visit) ? visit.id : visit
    },
    _visitStatus(day, child) {
      const date = this.no_of_days
      date.set('date', day)
      const visit = child.dates.find(d => moment(d.date)
        .isSame(date, 'day')) || null
      return visit
    },
    isWeekend(day) {
      const date = new Date(this.no_of_days)
      date.setDate(day)
      return date.getDay() === 6 || date.getDay() === 0
    },
    sumVisits(child) {
      return this.$store.state.info.countVisits[child.id]
    },
    handleEditVisit(data) {
      if (data.id) {
        this.$store.dispatch('info/listVisitScheduleUpdate', {
          id: data.id,
          children: data.children,
          visit_date: this.no_of_days.format('YYYY-MM') + '-' + data.day,
          visit_status: data.status
        })
          .then(() => {
            this.$store.dispatch('info/getVisit', { date: this.no_of_days.format('YYYY-MM') })
            this.$toast.success('Визит изменен', { timeout: 2000 })
            this.isAddListVisitSidebarActive = false
          })
          .catch(err => {
            this.$toast.error('Не удалось сохранить визит', { timeout: 2000 })
          })
      } else {
        this.$store.dispatch('info/listVisitScheduleCreate', {
          children: data.children,
          visit_date: this.no_of_days.format('YYYY-MM') + '-' + data.day,
          visit_status: data.status
        })
          .then(() => {
            this.$store.dispatch('info/getVisit', { date: this.no_of_days.format('YYYY-MM') })
            this.$toast.success('Визит сохранён', { timeout: 2000 })
            this.isAddListVisitSidebarActive = false
          })
          .catch(err => {
            this.$toast.error('Не удалось сохранить визит', { timeout: 2000 })
          })
      }
    },
    async print(data) {
      await this.$htmlToPaper(data)
    }
  }

}
</script>

<style scoped>
</style>
